import React from 'react'
import './TrainingStyles.css'
import { Link } from 'react-router-dom'

const Training = () => {
    return (
        <div className='training'>
            <div className='left'>
                <h1>Training</h1>
                <p>Training is tailored and based on customer requests and needs.
                    We services training regarding Bioinformatics, Statistics and Data Science.
                    Data Analytics and Data Engineering are also option available.
                </p>
                <Link to='/contact'><button className='btn'>Contact</button></Link>
            </div>
            <div className='right'>
                <h1>Training on offer</h1>
                <ul>
                    <li>Bioinformatics</li>
                    <li>Data Science</li>
                    <li>Statistics</li>
                    <li>Data Analytics</li>
                    <li>Data Engineering *</li>
                    <p>* This is based on smaller scale needs. Large Engineering projects that require major infrastructure
                        will have to be a discussion first
                    </p>
                </ul>

            </div>

        </div>
    )
}

export default Training
