import React from 'react'
import { Link } from 'react-router-dom'
import './PhotoStyles.css'


const Photo = () => {
    return (
        <div className='hero'>
            <div className='content'>
                <h1>Rhoquant Technologies</h1>
                <p>
                    Data Science, Bioinformatics and Data Analytics at it's best.
                </p>

                <div>
                    <Link to='/training' className='btn'>Training</Link>
                    <Link to='/services' className='btn btn-light'>Services</Link>
                </div>
            </div>
        </div>
    )
}

export default Photo
