import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import './FormStyles.css';

const Form = () => {

    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_8d0hhfq', 'template_mjev83x', form.current, {
                publicKey: '-pY1A0pLLv9rNcXCC',
            })
            .then(
                () => {
                    alert('SUCCESS!');
                    navigate('/')
                },
                (error) => {
                    alert('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='form'>
            <form  ref={form} onSubmit={sendEmail}>
                <label>Your Name</label>
                <input type='text' name="user_name"></input>
                <label>Email</label>
                <input type='email' name="user_email"></input>
                <label>Details</label>
                <textarea name='message' rows='6' placeholder='Type a short message here.' />
                <button className='btn'>Submit</button>
            </form>
        </div>
    )
}

export default Form
