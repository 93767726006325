import React from 'react'
import NavBar from '../components/Navbar'
import Footer from '../components/Footer'
import Heroimage from '../components/Heroimage'
import Form from '../components/Form'

const Contact = () => {
    return (
        <div>
            <NavBar />
            <Heroimage heading='CONTACT' text='Contact Rhoquant Technologies' />
            <Form />
            <Footer/>
        </div>
    )
}

export default Contact
