import React from 'react'
import NavBar from '../components/Navbar'
import Footer from '../components/Footer'
import Heroimage from '../components/Heroimage'
import ServicesCard from '../components/ServicesCard'

const Pricing = () => {
    return (
        <div>
            <NavBar />
            <Heroimage heading='SERVICES ON OFFER' text='We provide a wide array of services
            within data science, bioinformatics or general data analytics.' />
            <ServicesCard />
            <Footer />

        </div>
    )
}

export default Pricing
