import React from 'react'
import { FaMailBulk, FaPhone, FaSearchLocation } from 'react-icons/fa'
//import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import './FooterStyles.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='location'>
                        <FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                        <div>
                            <p>1 Albatros str</p>
                            <h4>Bloubergrant, Cape Town RSA</h4>
                        </div>
                    </div>
                    <div className='phone'>
                        <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />+27 (0)76-811-3272</h4>
                    </div>
                    <div className='email'>
                        <h4><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                            <Link to='' onClick={() => window.location = 'mailto:RoelofVanWyk@RhoquantTechnologies.com'}>
                            RoelofVanWyk@RhoquantTechnologies.com
                            </Link>
                        </h4>
                    </div>
                </div>
                <div className='right'>
                    <h4>About the company</h4>
                    <p>We offer a wide array of services from consulting, hands on programming and automation.
                        We specialize in Data Science, Data Analytics and Bioinformatics. We serve
                        bespoke builds and offer development of AI based services. Please contact us and
                        arrange for a consult: <Link to='/contact'><ins>contact us</ins></Link>
                    </p>
                    {/*<div className='social'>
                        <FaFacebook size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
                        <FaTwitter size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
                        <FaLinkedin size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
                    </div>*/}       
                </div>
            </div>

        </div>
    )
}

export default Footer
