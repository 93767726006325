import React from 'react'
import NavBar from '../components/Navbar'
import Footer from '../components/Footer'
import TrainingSection from '../components/Training'
import Heroimage from '../components/Heroimage'

const Training = () => {
    return (
        <div>
            <NavBar />
            <Heroimage heading='TRAINING' text='Training offers for Bioinformatics, Statistics and Data Science.
                                                Training is tailored to client needs.' />
            <TrainingSection />
            <Footer />
        </div>
    )
}

export default Training
