import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './routes/Home'
import Services from './routes/Services'
import Training from './routes/Training'
import Contact from './routes/Contact'

function App() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/training' element={<Training />} />
            <Route path='/contact' element={<Contact />} />
        </Routes>
    );
}

export default App;
