import React from 'react'
import NavBar from '../components/Navbar'
//import Video from '../components/Video'
import Photo from '../components/Photo'
import Footer from '../components/Footer'

const Home = () => {
    return (
        <div>
            <NavBar />
            <Photo />
            <Footer />
        </div>
    )
}

export default Home
