import React from 'react'
import { Link } from 'react-router-dom'

import './ServicesCardStyles.css'

const ServicesCard = () => {
    return (
        <div className='service-div'>
            <div className='card-container'>
                <div className='card'>
                    <h3>- Bioinformatics -</h3>
                    <span className='bar'></span>
                    <p>- Genomics -</p>
                    <p>- Functional Genomics -</p>
                    <p>- Single Cell NGS data -</p>
                    <p>- Gene Regulatory Networks -</p>
                    <p>- Machine Learning -</p>
                    <p>- Experimental Design -</p>
                    <p>- Automation -</p>
                    <div className='book-div'>
                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                </div>
                <div className='card'>
                    <h3>- Data Science -</h3>
                    <span className='bar'></span>
                    <p>- Data Transformation -</p>
                    <p>- Data Interpolation -</p>
                    <p>- Data Investigation And Summary -</p>
                    <p>- Problem Statement And Solutions -</p>
                    <p>- Machine Learning -</p>
                    <p>- Forecasting -</p>
                    <p>- Predictive Modelling -</p>
                    <p>- Insights And Reporting -</p>
                    <p>- Automation -</p>
                    <div className='book-div'>
                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                </div>
                <div className='card'>
                    <h3>- Statistics -</h3>
                    <span className='bar'></span>
                    <p>- Summary Statistics -</p>
                    <p>- Data Transformation -</p>
                    <p>- Data Interpolation -</p>
                    <p>- Population Comparisons -</p>
                    <p>- Correlations And Statistical Testing -</p>
                    <p>- Bootstrapping -</p>
                    <p>- Insights And Reporting -</p>
                    <div className='book-div'>
                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                </div>
                <div className='card'>
                    <h3>- Data Analytics -</h3>
                    <span className='bar'></span>
                    <p>- Summary Statistics -</p>
                    <p>- Data Transformation -</p>
                    <p>- Data Interpolation -</p>
                    <p>- Data Reporting -</p>
                    <div className='book-div'>
                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesCard
